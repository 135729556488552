import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import I18n from "../../../config/i18n/I18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import { useHistory } from "react-router-dom";
import classes from "./Header.module.scss";
import { AuthContext } from "../../../App";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuDropdown from "../menuDropdown/MenuDropdown";
import { Box, IconButton } from "@mui/material";
import logo from "../../../assets/logo/logo.png";
type carouselPropsType = {};
const Header: React.FC<carouselPropsType> = (props: carouselPropsType) => {
    /**
     * useRouter hook used to navigate between routes
     */
    let router = useHistory();
    const context = useContext(AuthContext);
    const isConnected = context ? context.isConnected : false;
    /**
     * Log out
     */
    function logOut() {
        localStorage.removeItem("user");
        localStorage.removeItem("isConnected");
        if (context) {
            context.setIsConnected(false);
        }
        router.push("");
    }
    return (
        <AppBar position="fixed" color="primary">
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <IconButton
                    className={classes.headerLogoContainer}
                    onClick={() => router.push("/")}
                >
                    <img
                        id={classes.headerLogoImage}
                        src={logo}
                        width="85px"
                        height="66.469px"
                        alt="soinsdm logo"
                    />
                </IconButton>
                <div></div>
                <Box
                    className={[
                        classes.HeaderButtonsContainer,
                        classes.MenuDisplay,
                    ].join(" ")}
                    display={{ xs: "none", md: "flex" }}
                    flexDirection="row"
                >
                    {!isConnected ? (
                        <Button
                            color="secondary"
                            onClick={() => router.push("/rdv")}
                        >
                            {I18n.appointment}&nbsp;&nbsp;
                            <InsertInvitationOutlinedIcon />
                        </Button>
                    ) : null}
                    {!isConnected ? (
                        <Button
                            color="secondary"
                            onClick={() => router.push("/professional")}
                        >
                            {I18n.professional}&nbsp;&nbsp;
                            <WorkOutlineOutlinedIcon />
                        </Button>
                    ) : null}
                    {isConnected ? (
                        <Button
                            color="secondary"
                            onClick={() => router.push("/dashboard")}
                        >
                            {I18n.dashboard}&nbsp;&nbsp;
                            <DashboardIcon />
                        </Button>
                    ) : null}
                    {isConnected ? (
                        <Button
                            color="secondary"
                            onClick={() => router.push("/dashboard/pending")}
                        >
                            {I18n.pendingList}&nbsp;&nbsp;
                            <ListAltIcon />
                        </Button>
                    ) : null}
                    {isConnected ? (
                        <Button
                            color="secondary"
                            onClick={() => router.push("/dashboard/profile")}
                        >
                            {I18n.profile}&nbsp;&nbsp;
                            <AccountCircleIcon />
                        </Button>
                    ) : null}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => router.push("/about")}
                    >
                        {I18n.aboutUs}&nbsp;&nbsp;
                        <InfoOutlinedIcon />
                    </Button>
                    {isConnected ? (
                        <Button color="secondary" onClick={logOut}>
                            {I18n.logOut}&nbsp;&nbsp;
                            <ExitToAppIcon />
                        </Button>
                    ) : null}
                </Box>
                <Box display={{ md: "none" }}>
                    <MenuDropdown
                        isConnected={isConnected}
                        navigate={(link: string) => {
                            router.push(link);
                        }}
                        logOut={logOut}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
